export const colors = {
  brand: {
    primary: '#665BE0',
    primaryDark: '#4F43D3',
    secondary: '#F0EFFC',
    lightPrimary: '#E3E3FF',
    tertiary: '#80CDF0',
    tertiary2: '#F7D3D7',
    dark: '#1A1A2E',
    'surface/dim': '#F1F0F6',
    darkColors: {
      0: '#1D1D25',
      10: '#1B1B24',
      20: '#2C2C40'
    },
    surface: {
      high: '#1D1D25',
      medium: '#4A485F',
      low: '#828095'
    },
    muted: '#B9B9DC',
    success: '#4AAE8C',
    white: '#ffffff',
    black: '#000000',
    gray: '#d7d7d7',
    grey: {
      0: '#9595A4',
      5: '#A5A7BD',
      10: '#C5C5CD',
      15: '#718096',
      20: '#7E7E99',
      25: '#40405C',
      30: '#F8F8FF',
      40: '#F4F4FA'
    },
    border: {
      default: '#CFCEDC'
    },
    active: '#EAE8FC',
    yellow: '#FFC250',
    lightGreen: '#66BC97',
    error: '#EE7D8A',
    red: {
      0: '#E85B81'
    },
    purple: {
      500: '#665BE0'
    }
  },
  text: {
    muted: '#7A7A9D',
    error: '#AA0009'
  },
  input: {
    placeholder: '#AEAEC6'
  }
}
