'use client'

import {
  Box,
  Button,
  Flex,
  Icon,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  VStack
} from '@chakra-ui/react'
import Link from 'next/link'
import React, { useState, ReactNode } from 'react'
import { colors } from 'styles/foundations'
import { FaChevronDown, FaFacebook } from 'react-icons/fa'
import { FaInstagram } from 'react-icons/fa'
import { FaTwitter } from 'react-icons/fa'
import { FaYoutube } from 'react-icons/fa'
import ScrollProgressBar from 'components/scroll-progress'
import NavDrawer from './nav-drawer/'
import { Logo } from 'components/logo'
import { IoMdMenu } from 'react-icons/io'
import { IoNewspaperSharp } from 'react-icons/io5'
import { BsCreditCardFill } from 'react-icons/bs'
import '../home/style/styles.css'
import { GrLinkedinOption } from 'react-icons/gr'

export default function NavBar() {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen)
  }
  return (
    <Box pos={'fixed'} left={0} w={'full'} shadow="sm" bg={colors.brand.white} zIndex="popover">
      <ScrollProgressBar />
      <Flex
        w={{ base: 'full' }}
        mx="auto"
        px={{ base: '2rem', md: '4rem', xl: '6rem' }}
        justifyContent="space-between"
        alignItems={'center'}
        h={28}>
        <Box width="20%">
          <Logo />
        </Box>
        {/* <Flex align={'center'} gap={16}> */}
        <Box
          display={{ md: 'flex', base: 'none' }}
          alignItems={'center'}
          gap={5}
          width={'20%'}
          justifyContent={'center'}>
          <Link
            target={'_blank'}
            href="https://www.facebook.com/profile.php?id=100094364470081&locale=hi_IN&paipv=0&eav=AfbNZE8OtIIhwse5wlxg1Yc1gVjT1T5xO7MpoXnw84Y0-Kl77wmKkcyOKuJrVIlQM-I">
            <Icon as={FaFacebook} boxSize={'10'} />
          </Link>
          <Link target={'_blank'} href="https://www.instagram.com/lint_finance/">
            <Icon as={FaInstagram} boxSize={'10'} />
          </Link>
          <Link target={'_blank'} href="https://twitter.com/lint_finance">
            <Icon as={FaTwitter} boxSize={'10'} />
          </Link>
          <Link target={'_blank'} href="https://www.linkedin.com/company/lint-finance">
            <Icon as={GrLinkedinOption} boxSize={'10'} />
          </Link>
          <Link target={'_blank'} href="https://www.youtube.com/@LintFinance">
            <Icon as={FaYoutube} boxSize={'10'} />
          </Link>
        </Box>
        {/* <Icon as={RiMenuFill} display={{ md: 'none', base: 'flex' }} /> */}
        <Flex>
          <Icon
            as={IoMdMenu}
            boxSize={12}
            onClick={toggleDrawer}
            display={{ md: 'none', base: 'flex' }}
          />

          <Box display={{ md: 'flex', base: 'none' }} alignItems={'center'} gap={8}>
            {/* <Link href="/auth/login">
                <Button size="16px" variant={'unstyled'} height={14} px={5}>
                  Login
                </Button>
              </Link> */}
            <Menu>
              <MenuButton>
                <Flex align="center" gap="8px">
                  <Text color="brand.surface.high" fontSize="16px" fontWeight="bold">
                    Our Resources
                  </Text>
                  <Icon as={FaChevronDown} fontSize="16px" color="brand.surface.high" />
                </Flex>
              </MenuButton>
              <MenuList
                shadow="-4px 16px 100px 0px rgba(0, 0, 0, 0.15)"
                borderRadius="8px"
                px="10px"
                py="8px">
                <MenuItem borderRadius="8px" _hover={{ bg: '#F6F6FC' }}>
                  <Link href="/blog">
                    <DropdownItem
                      title="Blog"
                      text="Read articles on our blog"
                      icon={<Icon as={IoNewspaperSharp} color="brand.primary" fontSize="21px" />}
                    />
                  </Link>
                </MenuItem>
                <MenuItem borderRadius="8px" _hover={{ bg: '#F6F6FC' }}>
                  <Link href="/utility-cards">
                    <DropdownItem
                      title="Utility Cards"
                      text="Learn more about Lint utility cards"
                      icon={<Icon as={BsCreditCardFill} color="brand.primary" fontSize="21px" />}
                    />
                  </Link>
                </MenuItem>
                <MenuItem borderRadius="8px" _hover={{ bg: '#F6F6FC' }}>
                  <Link href="/campus-ambassadors">
                    <DropdownItem
                      title="Ambassador Program"
                      text="Become a Lint campus ambassador"
                      icon={
                        <Image
                          src="/images/utility/icons/graduation_cap.svg"
                          w="24px"
                          h="24px"
                          alt="icon"
                        />
                      }
                    />
                  </Link>
                </MenuItem>
              </MenuList>
            </Menu>
            <Link href="https://lint.onelink.me/8QeB/1l651f8u">
              <Button
                size="16px"
                variant={'unstyled'}
                border={`1px solid ${colors.brand.darkColors[0]}`}
                height={14}
                px={5}>
                Get the app
              </Button>
            </Link>
          </Box>
        </Flex>
        {/* </Flex> */}
      </Flex>

      <NavDrawer toggleDrawer={toggleDrawer} isDrawerOpen={isDrawerOpen} />
    </Box>
  )
}

export const DropdownItem = ({
  title,
  text,
  icon
}: {
  title: string
  text: string
  icon: ReactNode
}) => {
  return (
    <Flex w="full" align="center" gap="16px" py="8px">
      <Box>{icon}</Box>
      <VStack spacing="2px">
        <Text color="brand.surface.high" fontFamily="medium" fontSize="16px" w="full">
          {title}
        </Text>
        <Text color="brand.surface.low" fontSize="12px" fontWeight="light">
          {text}
        </Text>
      </VStack>
    </Flex>
  )
}
